<template>
  <VRow
    v-if="showNewDesign"
    align="center"
    dense
  >
    <VCol
      cols="auto"
      class="d-flex"
    >
      <LogoIcon />
    </VCol>
    <VCol
      cols="auto"
      class="d-flex"
    >
      <LogoTextIcon />
    </VCol>
  </VRow>
  <div
    v-else
    class="d-flex align-center justify-md-start justify-center"
  >
    <svg
      width="42"
      height="12"
      viewBox="0 0 42 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="36"
        cy="5"
        r="6"
        fill="#601EF3"
      />
      <circle
        cx="21"
        cy="5"
        r="6"
        fill="#00A0F2"
      />
      <circle
        cx="6"
        cy="5"
        r="6"
        fill="#FFB500"
      />
    </svg>

    <span class="ml-2 tt-text-body-2">
      {{ $t('talentech') }}
    </span>
  </div>
</template>

<script>
import LogoIcon from '@/components/icons/LogoIcon.vue';
import LogoTextIcon from '@/components/icons/LogoTextIcon.vue';

export default {
  name: 'TTLogo',
  components: { LogoTextIcon, LogoIcon },
  inject: {
    glFeatures: {
      from: 'glFeatures',
      default: [],
    },
  },
  computed: {
    showNewDesign() {
      return this.glFeatures('rebranding_potok_03_2023');
    },
  },
};
</script>
