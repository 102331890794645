<template>
  <VRow class="fill-height">
    <DialogWrapper
      v-model="showAgreement"
      fullscreen
      persistent
    >
      <Agreement
        :agreement="agreement"
        @close="handleCloseAgreement"
        @accept="handleAcceptAgreement"
      />
    </DialogWrapper>
    <VCol
      offset="1"
      cols="10"
      class="d-flex flex-column justify-center center-row"
    >
      <SignInCookie />
      <VCard
        :elevation="0"
        color="transparent"
        class="form-signin"
      >
        <VRow>
          <VCol cols="12">
            <HelloTitle
              :step="step"
              :phone="internationalPhone"
            />
          </VCol>
          <VCol
            cols="12"
            md="5"
            lg="5"
          >
            <VForm
              ref="formAuth"
              v-model="validForm"
              @submit.prevent
            >
              <template v-if="step === $options.LOGIN_STEPS.phone">
                <!-- Маску пока не юзаем v-mask="'+7 ### ### ## ##'"  -->
                <TTTextField
                  id="login"
                  key="login"
                  v-model.trim="phone"
                  x-large
                  autofocus
                  persistent-hint
                  name="login"
                  :placeholder="$t('placeholders.phone')"
                  :label="$t('sign_in.login_label')"
                  :hint="$t('sign_in.login_hint')"
                  :error="invalidPhoneNumber"
                  :error-messages="invalidPhoneNumber ? invalidPhoneNumberMessage : []"
                  :rules="phoneRules"
                  @keyup.enter="makeAuthRequest"
                  @keypress="handleKeyPress"
                />
              </template>

              <template v-if="step === $options.LOGIN_STEPS.code">
                <TTTextField
                  id="code"
                  key="code"
                  v-model="code"
                  x-large
                  autofocus
                  name="code"
                  :placeholder="$t('sign_in.code_phone')"
                  :error="invalidCode"
                  :error-messages="invalidCode ? invalidCodeMessage : []"
                  :maxlength="codeLength"
                  class="mb-6"
                  @keydown.space.prevent
                />

                <div
                  v-if="delaySecond > 0"
                  class="tt-text-body-2 tt-light-mono-64--text"
                >
                  <div v-if="isResendCode">
                    {{ $t('sign_in.new_code_send') }}
                  </div>
                  {{ $t('code_delay') }}
                  <span class="text-no-wrap">{{ $tc('seconds', delaySecond) }}</span>
                </div>
              </template>

              <TTBtn
                v-if="step === $options.LOGIN_STEPS.phone"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="invalidPhoneNumber"
                :loading="loading"
                x-large
                :color="!!isPossiblePhoneNumber? 'tt-primary':'tt-secondary'"
                class="mt-8 pa-5"
                data-test-value="get-code"
                @click="makeAuthRequest"
              >
                {{ $t('sign_in.get_code') }}
              </TTBtn>

              <TTBtn
                v-if="step === $options.LOGIN_STEPS.code && delaySecond < 1"
                x-small
                color="tt-ghost"
                plain
                class="pa-0 font-weight-regular"
                data-test-value="get-code-again"
                @click="resendCode"
              >
                {{ $t('sign_in.send_again') }}
              </TTBtn>
            </VForm>
          </VCol>
        </VRow>
      </VCard>

      <PrivacySupport class="privacy-support" />
    </VCol>
  </VRow>
</template>

<script>
import { mask } from 'vue-the-mask';
import * as cookies from '@/services/cookies';
import * as snamiApi from '@/services/api/snami';
import { loginByCode, loginRequest } from '@/services/api/snami';
import { LOGIN_STEPS, PHONE_CODE_LENGTH } from '@/helpers/constants';
import PrivacySupport from '@/components/signin/PrivacySupport.vue';

import HelloTitle from '@/components/signin/HelloTitle.vue';
import SignInCookie from '@/components/signin/SignInCookie.vue';
import Agreement from '@/components/signin/Agreement.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import {
  isPossiblePhoneNumber, ParseError, parsePhoneNumberWithError,
} from 'libphonenumber-js';

export default {
  name: 'SignIn',

  components: {
    DialogWrapper,
    Agreement,
    SignInCookie,
    HelloTitle,
    PrivacySupport,
  },

  directives: { mask },

  inject: ['Names'],

  LOGIN_STEPS,

  data() {
    return {
      showAgreement: false,
      /*eslint-disable */
      agreement: {},
      token: {},
      /* eslint-enable */
      phone: '',
      code: '',
      invalidPhoneNumber: false,
      invalidPhoneNumberMessage: [],
      validForm: false,
      invalidCode: false,
      invalidCodeMessage: [],
      step: LOGIN_STEPS.phone,

      // codeRules: [(v) => v.length === 4 || 'Введите код'],
      phoneRules: [(v) => !!v || this.$t('sign_in.errors.wrong_phone')],
      delaySecond: 0,
      loginCode: null,
      isResendCode: false,
      loading: false,
    };
  },

  computed: {
    codeLength() {
      return PHONE_CODE_LENGTH;
    },
    preparedPhone() {
      return this.phone.includes('+') ? this.phone : `+${this.phone}`;
    },
    phoneNumber() {
      try {
        return parsePhoneNumberWithError(this.preparedPhone);
      } catch (e) {
        if (e instanceof ParseError) {
          return false;
        }
        throw e;
      }
    },
    internationalPhone() {
      if (!this.phoneNumber) {
        return false;
      }
      return this.phoneNumber.formatInternational();
    },

    isPossiblePhoneNumber() {
      if (!this.phoneNumber) {
        return false;
      }
      return isPossiblePhoneNumber(this.preparedPhone);
    },

  },

  watch: {
    code(next, prev) {
      if (next.length > prev.length && next.length === this.codeLength) {
        this.makeLogin();
      }
    },
    delaySecond() {
      if (this.delaySecond === 0) {
        clearInterval(this.timer);
      }
    },
    phone(newPhone, prevPhone) {
      if (newPhone !== prevPhone) {
        this.clearValidation();
      }
    },
  },
  mounted() {
    if (this?.$route?.query?.code) {
      this.loginByCode(this.$route.query.code);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    handleKeyPress($event) {
      // +7(905)0335746
      this.clearValidation();
      const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '+'];
      if (!allowedKeys.includes($event.key)) {
        $event.preventDefault();
      }
    },

    handleCloseAgreement() {
      this.showAgreement = false;
      this.step = this.$options.LOGIN_STEPS.phone;
      clearInterval(this.timer);
      this.token = {};
      this.clearValidation();
      this.phone = '';
      this.code = '';
    },
    async handleAcceptAgreement() {
      try {
        await snamiApi.acceptAgreement();
        window.location.replace('/');
      } catch (e) {
        // TODO Обработчик ошибок
        console.log(e);
      }
    },
    showAgreementDialog() {
      this.showAgreement = true;
    },
    resendCode() {
      this.isResendCode = true;
      this.makeAuthRequest();
    },
    async makeAuthRequest() {
      if (this.isPossiblePhoneNumber && this.$refs.formAuth.validate()) {
        try {
          this.loading = true;
          const response = await loginRequest(this.normalizePhone(), 'sms');
          const { loginCode } = response;
          this.loginCode = loginCode;
          this.step = this.$options.LOGIN_STEPS.code;
          this.startTimer();
        } catch (err) {
          const message = err.response?.data?.snackMessage || this.$t('notify.error');
          this.invalidPhoneNumber = true;
          this.invalidPhoneNumberMessage = [message];
        } finally {
          this.validForm = false;
          this.loading = false;
        }
      }
      return false;
    },
    async makeLogin() {
      if (this.$refs.formAuth.validate()) {
        this.loginByCode(`${this.loginCode}${this.code}`);
      }
      return false;
    },
    async loginByCode(code) {
      try {
        // eslint-disable-next-line max-len,vue/max-len
        const response = await loginByCode(code, code);
        const { token } = response;
        this.setToken({ token: token.token });

        if (response && response?.agreement) {
          this.token = response?.token;
          this.agreement = response.agreement;
          this.showAgreementDialog();
        } else {
          window.location.replace('/');
        }
      } catch (err) {
        const message = err.response?.data?.snackMessage
          || (err.response?.status === 401 ? this.$t('sign_in.errors.wrong_code') : this.$t('notify.error'));
        this.invalidCode = true;
        this.invalidCodeMessage = [message];
      } finally {
        this.validForm = false;
      }
    },
    setToken({
      token,
      expires = 365,
    }) {
      try {
        cookies.accessToken.set(token, {
          expires,
        });
      } catch (e) {
        // TODO Обработчик ошибок
        console.log(e);
      }
    },

    clearValidation() {
      this.invalidPhoneNumber = false;
    },
    normalizePhone() {
      if (this.preparedPhone.includes('+')) {
        return this.preparedPhone.replace('+', '');
      }
      return this.preparedPhone;
      // return this.phoneNumber.countryCallingCode + this.phoneNumber.nationalNumber;
    },
    startTimer() {
      this.delaySecond = 60;
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        this.delaySecond -= 1;
      }, 1000);
    },
  },
};
</script>
<style scoped>
.form-signin {
  margin: auto 0;
}

.privacy-support {
  flex: 0;
}

.center-row {
  position: relative;
}
</style>
