<template>
  <VRow v-if="showNewDesign">
    <VCol
      cols="12"
    >
      <TTLogo />
    </VCol>
    <VCol
      v-if="step === $options.LOGIN_STEPS.code"
      cols="12"
    >
      <h1 class="tt-text-title-1 mb-2">
        {{ $t('sign_in.type_sms_code') }}
      </h1>
      <div class="mb-2 tt-text-body-2 tt-light-mono-64--text">
        {{ $t('sign_in.we_send_sms') }} <span class="text-nowrap">{{ phone }}.</span><br>
        {{ $t('sign_in.we_send_sms2') }}
      </div>
    </VCol>
  </VRow>
  <VRow v-else>
    <VCol
      cols="12"
    >
      <TTLogo />
    </VCol>
    <VCol
      v-if="step === $options.LOGIN_STEPS.phone"
      class="mb-md-6 mb-3"
    >
      <h1
        class="text-center text-md-start"
        :class="$vuetify.breakpoint.smAndDown ? 'tt-text-headline-2':'tt-text-display'"
      >
        {{ $t('sign_in.hello') }}<br>
        {{ $t('sign_in.hello_in') }}
      </h1>
      <VImg
        :src="require('@/assets/signin/stripes.png')"
        :max-width="$vuetify.breakpoint.xsOnly ? 350 : 490"
        class="ml-md-9 mt-1 mx-auto"
      />
    </VCol>
    <VCol
      v-if="step === $options.LOGIN_STEPS.code"
      cols="5"
    >
      <h1 class="tt-text-title-1 mb-2">
        {{ $t('sign_in.type_sms_code') }}
      </h1>
      <div class="mb-2 tt-text-body-2 tt-light-mono-64--text">
        {{ $t('sign_in.we_send_sms') }} <span class="text-nowrap">{{ phone }}.</span><br>
        {{ $t('sign_in.we_send_sms2') }}
      </div>
    </VCol>
  </VRow>
</template>

<script>
// TODO: refactor
import { LOGIN_STEPS } from '@/helpers/constants';
import TTLogo from '@/components/signin/TTLogo.vue';

export default {
  name: 'HelloTitle',
  components: { TTLogo },
  inject: {
    glFeatures: {
      from: 'glFeatures',
      default: [],
    },
  },
  props: {
    step: {
      type: String,
      default: LOGIN_STEPS.phone,
    },

    mail: {
      type: Boolean,
      default: false,
    },

    phone: {
      type: [String, Boolean],
      default: '',
    },
  },

  LOGIN_STEPS,

  computed: {
    showNewDesign() {
      return this.glFeatures('rebranding_potok_03_2023');
    },
  },
};
</script>
