<template>
  <TTNotify
    :value="showCookie"
    app
    top
    left
    absolute
    :icon="false"
    timeout="-1"
  >
    {{ $t('notify.cookies') }}
    <div>
      <a
        @click="setShowdCookie"
      >
        {{ $t('notify.accept') }}
      </a>
      {{ $t('notify.or') }}
      <a
        href="https://storage.talenttech.ru/public/files/Policies/policy.pdf"
      >
        {{ $t('notify.more_info') }}
      </a>
    </div>
  </TTNotify>
</template>

<script>
import { showdCookie } from '@/services/cookies';

export default {
  name: 'SignInCookie',

  inject: ['Names'],

  data() {
    return {
      showCookie: !showdCookie.get(),
    };
  },
  methods: {
    setShowdCookie() {
      this.showCookie = false;
      showdCookie.set(1);
    },
  },
};
</script>
<style scoped>
.v-snack {
  top: 20px;
}
</style>
