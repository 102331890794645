<template>
  <VContainer class="pa-6">
    <VRow>
      <VCol
        :offset="$vuetify.breakpoint.xsOnly? 0 : 1"
      >
        <VRow justify="space-between">
          <VCol cols="11">
            <div
              :class="[$vuetify.breakpoint.xsOnly? 'tt-text-headline-1':'tt-text-display']"
              data-test="title-acceptance-of-personal-data"
              v-html="agreement.header"
            />
          </VCol>
          <VCol
            v-if="!$vuetify.breakpoint.xsOnly"
            cols="1"
          >
            <VRow
              justify="center"
            >
              <VCol cols="auto">
                <TTBtn
                  fab
                  color="white"
                  elevation="1"
                  large
                  class="mb-2"
                  data-test-label="button-close"
                  @click="handleClose"
                >
                  <VIcon
                    color="tt-black"
                    size="19"
                  >
                    fal fa-times
                  </VIcon>
                </TTBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="11">
            <VRow>
              <VCol>
                <div
                  data-test="text-acceptance-of-personal-data"
                  v-html="agreement.body"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <div
                  data-test="attention-acceptance-of-personal-data"
                  v-html="agreement.attention"
                />
              </VCol>
            </VRow>
            <VRow
              justify="space-between"
              class="my-6"
            >
              <VCol
                v-if="$vuetify.breakpoint.xsOnly"
                cols="auto"
              >
                <TTBtn
                  large
                  text
                  data-test-label="button-decline"
                  @click="handleClose"
                >
                  {{ $t('agreement.close') }}
                </TTBtn>
              </VCol>
              <VCol cols="auto">
                <TTBtn
                  large
                  data-test-label="button-read-and-agree"
                  @click="handleAccept"
                >
                  {{ agreement.button || $t('agreement.read_and_agree') }}
                </TTBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>

export default {
  name: 'Agreement',
  props: {
    /**
     * @type {{
     * header: string,
     * body: string,
     * attention: string,
     * button: string,
     * }}
     */
    agreement: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    handleAccept() {
      this.$emit('accept');
    },
  },
};
</script>

<style scoped>

</style>
