<template>
  <VRow>
    <VCol
      cols="12"
      class="mb-lg-10 d-md-flex"
    >
      <div class="d-flex justify-center text-md-start text-center">
        <a
          class="tt-text-body-2 text-decoration-none tt-light-mono-46--text"
          href="https://storage.talenttech.ru/public/files/Policies/policy.pdf"
        >
          {{ $t('notify.privacy_policy') }}
          <VIcon
            right
            size="10"
            color="tt-light-blue"
          >
            fal fa-chevron-right
          </VIcon>
        </a>
      </div>
      <div class="ml-md-16 mt-2 mt-md-0 d-flex justify-center">
        <a
          class="tt-text-body-2 text-decoration-none tt-light-mono-46--text"
          href="mailto:help@talenttech.ru"
        >
          {{ $t('notify.support') }}
          <VIcon
            right
            size="10"
            color="tt-light-blue"
          >
            fal fa-chevron-right
          </VIcon>
        </a>
      </div>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'PrivacySupport',
};
</script>
